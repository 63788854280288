import React, { useState } from 'react';

import T from '../Translate';

type Props = {
  text: string;
  maxLength?: number;
  className?: string;
};

const ReviewText = ({ text, maxLength = 265, className }: Props) => {
  const [showFull, setShowFull] = useState(false);

  if (text === null) return null;

  const fullText = text.replace(/<[^>]*>?/gm, '');
  const previewText = fullText.substring(0, maxLength);
  const isOverflowing = fullText.length > maxLength;

  if (fullText.trim() === '') return null;

  return (
    <>
      {showFull ? (
        <p className={className}>
          {fullText}
          {/*<a
              href="#"
              onClick={ (e) => { e.preventDefault(); setShowFull(false);} }
            >
              Skrýt
            </a>*/}
        </p>
      ) : (
        <p className={className}>
          {previewText}
          {isOverflowing && (
            <>
              ...
              <button
                className="btn btn-link px-0 py-0"
                onClick={() => {
                  setShowFull(true);
                }}
                type="button"
              >
                <T id="global.wholeRating" />
              </button>
            </>
          )}
        </p>
      )}
    </>
  );
};

export default ReviewText;
