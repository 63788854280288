import React from 'react';
import classNames from 'classnames';

import { getNavigationLink } from '../../model/Location';
import { isTripCancelled, isTripFinished, isTripRequest, type Trip } from '../../model/Trip';
import RouteEnum from '../../RouteEnum';
import { formatDatelike } from '../../services/DateTime';
import Tracking from '../../services/Tracking';
import Image from '../Image';
import Link from '../Link';
import Price from '../Price';
import { RestrictionProvider } from '../Restriction';
import T from '../Translate';
import StarRating from '../User/StarRating';

import TripCardBody from './TripCardBody';
import TripCardFooter from './TripCardFooter';

const DATETIME_FORMAT = 'd. M. H:mm';

type Props = {
  trip: Trip;
  className?: string;
};

export default function TripCard({ trip, className }: Props) {
  const fromDate = formatDatelike(trip.date_from, DATETIME_FORMAT);
  const toDate = formatDatelike(trip.date_to, DATETIME_FORMAT);
  const location = trip.vehicle.locations.find(l => l.type === 'parking');

  return (
    <article className={classNames('vehicle-box', className)}>
      <RestrictionProvider region={trip.vehicle.country.alpha2_code}>
        <header className="vehicle-box__header">
          <figure className="vehicle-box__figure">
            <Image alt="vehicle" size="md" src={trip.vehicle.main_image} />
          </figure>

          <div className="vehicle-box__header--body">
            <div className="vehicle-box__aside">
              <div className="vehicle-box__aside--info">
                <h2 className="vehicle-box--title" id="vehicle-box-01">
                  {trip.vehicle.name}
                </h2>
                <div aria-label="Hodnocení" className="rating d-inline-flex" data-rating-size="xs">
                  <StarRating rating={trip.vehicle.rating_average_int} />
                </div>

                <span className="text-muted font-size-sm">
                  {trip.vehicle.ratings_count} &#8226;
                  {trip.owner.first_name} {trip.owner.last_name.substring(0, 1)}
                </span>
              </div>

              <div className="vehicle-box__aside--action">
                {!isTripRequest(trip) && !isTripCancelled(trip) && !isTripFinished(trip) && trip.owner.phone ? (
                  <a
                    aria-label="Telefon"
                    className="round-badge"
                    data-badge-color="white"
                    href={`tel:${trip.owner.phone}`}
                    onClick={() => {
                      Tracking.track('TRIP_CARD_CALL_CLICKED', trip.hash);
                    }}
                  >
                    <i className="icon icon-phone" />
                  </a>
                ) : null}
                <Link
                  aria-label="Message"
                  className="round-badge"
                  data-badge-color="white"
                  params={{ chat: trip.hash }}
                  to={RouteEnum.MESSAGES}
                  track={['TRIP_CARD_CHAT_CLICKED', trip.hash]}
                >
                  <i className="icon icon-message" />
                </Link>
              </div>
            </div>
          </div>
        </header>
        <main className="vehicle-box__main">
          <ul className="list">
            {process.env.NODE_ENV !== 'production' ? <li className="list__item">{trip.state}</li> : null}
            <li className="list__item">
              <div className="list__item--name">
                <T id="global.when" />
              </div>
              <div className="list__item--info">
                {fromDate} - {toDate}
              </div>
            </li>
            {location ? (
              <li className="list__item">
                <div className="list__item--name">
                  <T id="global.where" />
                </div>
                <div className="list__item--info">
                  <a href={getNavigationLink(location)} rel="noopener noreferrer" target="_blank">
                    <span className="icon icon-map mr-1" style={{ verticalAlign: '-2px' }} />
                    {location.street}, <span className="nowrap">{location.zip}</span> {location.city}
                  </a>
                </div>
              </li>
            ) : null}
            <li className="list__item">
              <div className="list__item--name">
                <T id="global.price" />
              </div>
              <div className="list__item--info">
                <Price price={trip.price} />
              </div>
            </li>
            <TripCardBody trip={trip} />
          </ul>
          <TripCardFooter trip={trip} />
        </main>
      </RestrictionProvider>
    </article>
  );
}
